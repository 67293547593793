html,
body,
#root {
  width: 100%;
  height: 100%;
}

a:hover {
  text-decoration: none;
}

body {
  color: #555c66;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #555555;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

button:focus {
  outline: 0;
}

strong {
  font-weight: 600 !important;
}

.root-container {
  height: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.app-content-container {
  height: 100%;
  margin: auto;
  max-width: 768px;
  padding-bottom: 24px;
  padding-top: 96px;
}

@media (max-width: 576px) {
  .app-content-container {
    padding-top: 32px;
  }
}

.centered-content-container {
  margin-bottom: -16px;
  margin: auto;
  max-width: 1024px;
  padding-top: 32px;
}

.portal-content-container {
  padding: 40px;
}

.custom-description {
  color: #000;
  font-size: 18px;
  margin-bottom: 32px;
}

.custom-description img {
  max-width: 100%;
}

.custom-title,
.custom-subtitle {
  color: #06115e;
  font-family: "TTFirs-Bold";
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-bottom: 16px;
}

.custom-subtitle {
  font-size: 16px;
}

.widget-title-container {
  display: block;
}

.portal-selection-page-title-container {
  padding: 48px 16px;
}

/* It removes scrolling on the body when a modal is open*/
.ReactModal__Body--open {
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .custom-title {
    font-size: 24px;
  }
  .portal-selection-page-title-container {
    padding: 0px 16px;
  }
}

.overlay-button {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.ico {
  vertical-align: middle;
  padding-bottom: 2px;
  padding-right: 8px;
}

.ico-small {
  font-size: 1em;
}
.ico-medium {
  font-size: 1.3em;
}
.ico-large {
  font-size: 1.5em;
}

.admin-checkbox {
  margin: 0px 10px;
}

/* Load font Poppins from Google API */
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700");

/* Load TTFirs from S3 */
@font-face {
  font-family: "TTFirs-Bold";
  src: url("https://s3.eu-central-1.amazonaws.com/cobble-flow/cobble/assets/fonts/TT_Firs/TTFirs-Bold.otf")
    format("truetype");
}

@font-face {
  font-family: "TTFirs-ExtraBold";
  src: url("https://s3.eu-central-1.amazonaws.com/cobble-flow/cobble/assets/fonts/TT_Firs/TTFirs-ExtraBold.otf")
    format("truetype");
}

.cloudinary-image {
  width: inherit;
}
