@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700);
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.33);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.modal-content-base {
  background: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
  outline: none;
  position: absolute;
  max-height: 100%;
}

.modal-content {
  bottom: 10%;
  left: 10%;
  overflow: hidden;
  right: 10%;
  top: 10%;
}

/* 80% height and 620px width*/
.modal-medium-content {
  bottom: 10%;
  left: 50%;
  overflow: hidden;
  top: 10%;
  transform: translateX(-50%);
  width: 620px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-transform: translateX(-50%);
}

/* centered modal with varying width and height */
.modal-small-content {
  left: 50%;
  overflow: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate(-50%, -50%);
}

.modal-close-icon {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-content-scroll-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-content-padding-container {
  height: 100%;
  padding: 16px 24px 16px 24px;
}

.murfy-calendar-container {
  border-radius: 7px 7px 7px 7px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.08), 0 1px 40px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
  width: 672px;
}

.murfy-calendar-chevron-container {
  color: #ffffff;
  cursor: pointer;
  font-size: 17px;
  margin: auto;
  padding: 0 2px;
  text-align: center;
  width: 10%;
}

.murfy-calendar-top-bar-container {
  background: #fad45e;
  border-radius: 7px 7px 0 0;
  height: 62px;
  margin: auto;
  width: 100%;
}

.murfy-calendar-timeslots-container {
  margin: 16px auto;
  width: 80%;
}

.murfy-calendar-day-container {
  color: #080d4d;
  font-size: 17px;
  text-align: center;
}

.murfy-calendar-days-container {
  align-items: center;
  display: flex;
  width: 80%;
}

.murfy-calendar-day-timeslots-container {
  padding: 0 8px;
}

.murfy-calendart-timeslot-high-priority-container {
  background-color: #f85b5b;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.9px;
  padding: 8px 0;
  margin: 16px;
  position: relative;
  text-align: center;
}

.murfy-calendar-timeslot-hours-container {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e4dbdb;
  color: #2b3b5c;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.9px;
  padding: 8px 0;
  margin: 16px;
  position: relative;
  text-align: center;
}

.murfy-calendar-loader-container {
  margin: auto;
  color: #fcd660;
  height: 308px;
  align-items: center;
  display: flex;
}

.murfy-calendar-timeslot-hours-container-selected {
  background-color: #fcd660;
  color: #06115e;
}

.murfy-calendar-timeslot-hours-container > p {
  opacity: 1;
}

.murfy-calendar-timeslot-hours-container:hover {
  background: rgba(252, 214, 96, 0.2);
}

.murfy-calendar-no-timeslot-container {
  cursor: not-allowed;
  font-size: 16px;
  font-weight: 600;
  height: 41px;
  padding: 8px 0;
  margin: 16px;
  display: flex;
  justify-content: center;
}

.murfy-calendar-day-weekday {
  font-weight: 600;
  letter-spacing: 0;
  font-size: 15px;
}

.murfy-calendar-next-timeslot-container .quick-booking-transition-button {
  background: #fcd660;
  color: #2b3b5c;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  width: 251px;
  height: 51px;
}

.murfy-calendar-next-timeslot-container {
  margin: auto;
  display: flex;
  align-items: center;
}

.murfy-calendar-timeslots-container.next-timeslot-message {
  background-color: rgba(252, 214, 96, 0.1);
  margin: 0;
  width: 100%;
  height: 340px;
}

.murfy-calendar-timeslots-container.no-next-timeslot-message {
  background-color: #ffffff;
}

.murfy-calendar-day-timeslots-container.complete {
  width: 33%;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.murfy-calendar-complete-day {
  width: 21px;
  height: 5px;
  background: #dbdfe8;
}

.add-element-button-container {
  font-size: 16px;
  padding: 12px 14px;
  cursor: pointer;
}

.add-element-button-container .add-element-button-disabled {
  font-style: italic;
}

.buttonContainer {
  border-radius: 5px;
  border-width: 0px;
  cursor: pointer;
  font-size: 14px;
  padding: 3px 30px;
}

.buttonContainer:focus {
  outline: none;
}

.buttonDisabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.categorized-elements-group-container {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 24px 0;
  padding: 16px;
}

.categorized-elements-title-container {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.categorized-elements-category-container {
  padding: 0 8px !important;
}

.categorized-elements-group-category-title {
  color: #1275d8;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.categorized-elements-group-element-container {
  margin: 8px 0;
}

.categorized-elements-element-container {
  font-size: 16px;
  overflow: hidden;
  padding: 12px 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.categorized-elements-element-container:hover {
  background-color: #f3f5f8;
}

.categorized-elements-group-categories-container {
  width: 100%;
}

.dropdown-edition-field-container {
  color: #495057;
  font-size: 16px;
  line-height: 28px;
  padding: 0 10px;
}

.dropdown-edition-field-container:hover {
  background-color: rgba(18, 107, 255, 0.5);
  cursor: pointer;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  padding-right: 25px;
}

.edition-box-container {
  background-color: #fff;
  border: solid 1px #cccccc;
  border-radius: 1px;
  margin-bottom: 10px;
}

.edition-box-title-container {
  background-color: #e6e8ea;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 42px;
  justify-content: space-between;
  letter-spacing: 1.8px;
  line-height: 1.75;
  padding: 8px 16px;
}

.edition-box-icons-container {
  display: flex;
}

.edition-box-icon {
  padding-top: 6px;
  padding-left: 16px;
}

.elementary-block {
  background-color: #f7f9fa;
  border: solid 1px #b9b9b9;
  border-radius: 1px;
  color: #132136;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  padding: 8px 14px;
}

.elementary-block:hover {
  background-color: rgba(18, 117, 216, 0.4);
  color: #132136;
}

.elementary-block-icons-tags-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.element-selected {
  border: 3px solid #016bff;
}

.elementary-block-icon {
  font-size: 20px;
  float: right;
}

.elementary-block-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-icon:hover {
  color: #728096;
}

.elementary-block-tag {
  background-color: #65cc44;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  font-weight: 100;
  height: 20px;
  margin-left: 8px;
  overflow: hidden;
  padding: 0px 8px;
  text-align: left;
  text-overflow: ellipsis;
  width: 68px;
  white-space: nowrap;
}

.edition-buttons-container {
  display: flex;
  justify-content: space-between;
  height: 64px;
}

.edition-button {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  height: 32px;
  text-align: center;
  width: 48%;
}

.cancel-button {
  background-color: #868686;
}

.confirm-button {
  background-color: #016bff;
}

/* Leave the prefix "pc" to prevent clash with quick access element group */

.pc-block-container {
  margin-bottom: 16px;
}

.pc-group-container {
  background-color: white;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 24px 0;
  padding: 16px 24px;
}

.pc-group-title {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.pc-group-title:first-letter {
  text-transform: capitalize;
}

.pc-new-element-container {
  font-size: 16px;
  overflow: hidden;
  padding: 12px 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.pc-new-element-container:hover {
  background-color: #f3f5f8;
}

.file-upload-container {
  margin: 8px 0;
  font-size: 14px;
}

.file-upload-title {
  color: #4a4a4a;
  letter-spacing: 1.8px;
  margin-bottom: 8px;
}

.file-upload-input {
  font-size: 14px;
  height: 28px;
  padding: 0 8px;
  width: 100%;
}

.main-input-container {
  color: #434343;
  display: flex;
}

.input-field-container {
  width: 310px;
}

.empty-field-icon-container {
  width: 40px;
}

.empty-field-icon {
  cursor: pointer;
  float: right;
  font-size: 24px;
  margin: -8px 0;
  padding-right: 4px;
  padding-top: 4px;
}

.filter-field-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
  letter-spacing: 1.8px;
  min-height: 30px;
  text-align: left;
  color: #ffffff;
}

.input-field {
  background-color: transparent;
  border: solid 1px #cccccc;
  line-height: 2;
  min-height: 30px;
  padding: 0 8px;
  outline: none;
  width: 100%;
}

::-webkit-input-placeholder {
  color: #8a93a1;
  font-style: normal;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #8a93a1;
  font-style: normal;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #8a93a1;
  font-style: normal;
}

:-ms-input-placeholder {
  color: #8a93a1;
  font-style: normal;
}

.fieldLabel {
  color: #555555;
  font-weight: 500;
  margin-bottom: 3px;
}

.fieldLabel::first-letter {
  text-transform: capitalize;
}

.images-management-main-container {
  background-color: #f3f5f8;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  height: 80%;
  margin: 16px 0;
}

.images-management-folder-tree-container {
  border-right: #000 1px solid;
  padding: 16px;
  width: 30%;
}

.images-management-images-container {
  padding: 24px;
  position: relative;
  width: 70%;
}

.images-management-images-subcontainer {
  height: calc(100% - 16px);
  overflow-y: scroll;
}

.images-management-current-folder-container {
  font-size: 18px;
  padding-bottom: 16px;
}

.images-management-all-subfolders-container {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
  min-height: 80%;
  padding: 16px;
  position: relative;
}

.images-management-subfolder-container {
  margin: 4px 0;
}

.images-management-subfolder-container:hover {
  cursor: pointer;
  text-decoration: underline;
}

.images-management-folder-title {
  color: #000;
}

.images-management-back-button {
  color: #000;
}

.images-management-back-button:hover {
  cursor: pointer;
}

.images-management-add-button {
  bottom: 8px;
  left: 8px;
  position: absolute;
}

.images-management-add-button:hover {
  cursor: pointer;
}
.images-management-image-preview-container {
  text-align: center;
}

.images-management-image-preview-image-container {
  height: 130px;
}

.images-management-upload-image-preview {
  margin-bottom: 16px;
  width: 300px;
}

.images-management-input-field {
  margin-bottom: 16px;
}

.images-management-input-field .file-upload-input {
  padding: 0;
}

.images-management-image-delete {
  padding-left: 4px;
}

.images-management-image-delete:hover {
  cursor: pointer;
}

.media-selection-field-container {
  font-size: 14px;
  margin: 12px -8px 4px -8px;
}

.media-selection-field-title {
  color: #000;
  font-weight: 600;
  letter-spacing: 0.9px;
  margin-bottom: 8px;
}

.media-selection-field-checkbox {
  margin: 0px 10px;
}

.media-selection-field-all-images-container {
  padding: 8px;
  color: #000;
}

.media-selection-field-image-container .media-selection-field-add-button-container {
  margin-bottom: 8px;
}

.media-selection-field-add-button-container:hover {
  cursor: pointer;
}

.menu-button-container {
  background: transparent;
  border: none;
  border-radius: 0px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 20px 12px;
}

.menu-button-container:hover {
  outline: none;
  box-shadow: none;
  background: #0d3a79;
}

.menu-button-container:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.menu-button-container-active {
  background: #016bff !important; /*Prevent changing background on hover*/
  color: white;
}

.menu-button-tooltip {
  color: #fff;
  font-size: 16px;
  letter-spacing: 1.3px;
  opacity: 1;
}

.menu-button-container {
  font-size: 28px;
}

.admin-blue-background {
  background-color: #132136;
}

.page-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.page-content-container {
  background-color: #f3f5f8;
  height: 100%;
  min-width: 960px;
  overflow: auto;
  width: calc(100% - 320px);
}

.quick-access-container {
  background-color: #fff;
  box-shadow: 2px 0 2px 0 rgba(150, 150, 150, 0.5);
  color: #000;
  float: left;
  font-weight: 500;
  height: 100%;
  min-width: 320px;
  padding: 16px 8px 32px 8px;
  overflow-y: auto;
  text-align: left;
  width: 320px;
  z-index: 0;
}

.quick-access-container .quick-access-title {
  color: #000;
  font-size: 24px;
  letter-spacing: 2.2px;
  padding-bottom: 24px;
}

.quick-access-container .quick-access-subtitle {
  display: inline-block;
  font-size: 16px;
  letter-spacing: 1.3px;
}

.quick-access-container .quick-access-subtitle:first-letter {
  text-transform: capitalize;
}

.qa-elementary-block {
  color: #434343;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  letter-spacing: 1.3px;
  padding: 4px 16px 4px 26px;
}

.qa-elementary-block:hover {
  background-color: rgba(18, 117, 216, 0.4) !important;
  color: #132136;
}

.qa-element-selected {
  border: 3px solid #ce9071;
}

.qa-elementary-block-icon {
  font-size: 20px;
  float: right;
}

.qa-elementary-block-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.qa-elementary-block-delete-icon:hover {
  color: #728096;
}

.qa-elements-groups-container {
  cursor: pointer;
  padding-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.qa-elements-groups-container .qa-elements-group-icon {
  color: #1275d8;
  font-size: 16px;
  padding: 0 5px;
}

.qa-menu-container {
  border-bottom: solid 1px #979797;
  color: #000;
  background-color: #fff;
  margin: 0 -8px;
  padding: 0 16px 16px 16px;
}

.qa-menu-container .qa-menu-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 2.2px;
  padding-bottom: 25px;
  text-align: center;
}

.qa-menu-container .qa-menu-elements-container {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.3px;
  text-align: left;
}

.qa-menu-container .qa-menu-element {
  cursor: pointer;
  padding-bottom: 8px;
  display: flex;
  align-items: baseline;
}

.qa-menu-container .qa-menu-subelement,
.qa-menu-container .qa-menu-subelement-counter {
  cursor: pointer;
  font-weight: 300;
  padding: 0 0 9px 18px;
}

.qa-menu-container .qa-menu-subelement-counter {
  font-size: 14px;
  padding-top: 4px;
}

.qa-menu-container .qa-menu-element-active {
  color: #126bff;
  font-weight: 600;
}

.qa-menu-container .qa-menu-subelement-active {
  color: #126bff;
  font-weight: 600;
}

.quick-access-menu-counter-container {
  margin-left: 5px;
  background-color: #e29641;
  border-radius: 20px;
  margin-top: 5px;
  height: 18px;
  min-width: 18px;
  display: flex;
  color: white;
  font-size: 11px;
  justify-content: center;
  padding: 1px;
}

.header-submenu-container {
  border-bottom: 1px solid #d6dde6;
  height: 60px;
}

.submenu-button {
  background: transparent;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  padding: 18px;
}

.submenu-button:hover {
  outline: none;
  box-shadow: none;
}

.submenu-button:focus {
  outline: none;
  box-shadow: none;
}

.submenu-button span {
  color: #223550;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.submenu-button-active {
  border-bottom: 2px solid #016bff;
}

.switch-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  padding: 0px 8px;
  width: 100%;
}

.switch-button {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 0.9px;
  text-align: center;
  width: 50%;
  height: 26px;
  cursor: pointer;
}

.unactive-switch {
  background-color: #e9e9e9;
  color: #7d7d7d;
}

.active-switch {
  background-color: #2f2f2f;
  color: #ffffff;
}

.tag-container {
  display: inline-table;
  margin-bottom: 3px;
}

.tag-container .tag {
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  display: table-cell;
  font-size: 13px;
  font-weight: 100;
  height: 26px;
  letter-spacing: 1px;
  padding-left: 6px;
  text-align: left;
  vertical-align: middle;
  width: 120px;
}

.tag-container .selected-tag-icon {
  color: white;
  cursor: pointer;
  float: right;
  margin-right: 12px;
}

.tag-container .delete-tag-icon {
  color: #89909a;
  cursor: pointer;
  font-size: 20px;
  margin-left: 12px;
  padding-bottom: 0;
}

.tag-container .delete-tag-icon:hover {
  color: #000;
}

.tag-alert-name {
  font-weight: bold;
}

.tag-alert-subtext {
  font-size: 14px;
}

.text-edition-field-container {
  margin: 12px 0 4px 0;
  flex: auto;
}

.text-edition-field-title-container {
  padding-bottom: 12px;
}

.text-edition-field-title-left-container {
  padding-right: 12px;
}

.text-edition-field-subcontainer {
  display: flex;
}

.text-edition-field-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.9px;
}

.text-edition-field-length-info {
  font-size: 12px;
}

.text-edition-field-textarea {
  border: solid 1px #cccccc;
  border-radius: 1px;
  color: #495057;
  font-size: 14px;
  line-height: 2;
  min-height: 30px;
  outline: none;
  padding: 0px 8px;
  resize: none;
  width: 100%;
}

/** Override React Quill CSS classes to fix font family and padding */
#text-edition-field .ql-editor {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding: 8px;
}

#text-edition-field .ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "Link";
}

#text-edition-field .ql-snow .ql-tooltip::before {
  content: "Go";
}

#text-edition-field .ql-snow .ql-tooltip a.ql-preview {
  max-width: 120px;
}

.toggle-content-container {
  font-size: 14px;
  margin: 12px 8px;
}

.toggle-content-title {
  color: #000;
  font-weight: 600;
  letter-spacing: 0.9px;
  margin-bottom: 8px;
}

.youtube-video-field-container {
  margin: 12px 8px;
  font-size: 14px;
}

.youtube-video-field-title {
  color: #4a4a4a;
  letter-spacing: 1.8px;
  margin-bottom: 8px;
}

.youtube-video-input {
  line-height: 2;
  min-height: 30px;
  outline: none;
  padding: 0 8px;
  width: 100%;
}

.detail-separator {
  margin: 0;
  border-top: 1px solid #8a93a1;
  width: 95%;
}

.details-title {
  color: #016bff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 3px;
}

.product-details {
  margin-top: 8px;
}

.products-container {
  margin-bottom: 16px;
}

.ticket-detail {
  display: inline-block;
  font-size: 14px;
  margin: 0;
}

.ticket-detail-key {
  font-weight: 600;
}

.ticket-detail-key-bullet-point {
  font-weight: 400;
  display: block;
}

.ticket-detail-value {
  color: #8a93a1;
  white-space: break-spaces;
}

.ticket-detail-comments {
  height: 150px;
  width: 80%;
  border-radius: 3px;
  color: #555c66;
}

.ticket-detail-input {
  font-size: 12px;
  padding-left: 8px;
  width: 30%;
}

.ticket-detail-ico {
  padding-left: 8px;
}

.ticket-detail-ico:hover {
  cursor: pointer;
}

.ticket-detail-comments-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 220px;
}

.ticket-detail-button-container {
  width: 100px;
}

.detail-label-visit-select {
  width: 300px;
}

.detail-label-visit-checkbox-container {
  width: 300px;
  color: #212529;
  display: flex;
  align-items: center;
}

.ticket-detail-history-container {
  margin-top: 24px;
}

.detail-label-visit-container {
  margin-top: 24px;
  display: flex;
  max-width: 700px;
}


.add-new-visit {
  width: 100%;
  height: 80px;
  background: #f4f3f8;
  opacity: 0.6;
  border: 1px dashed #8795b3;
  box-sizing: border-box;
  border-radius: 4px;

  color: #8795b3;
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4;
  text-align: center;
}

.add-new-visit:hover {
  background-color: #e4e3e8;
  cursor: pointer;
}

.plus-span {
  font-size: 24px;
}

.inline-title {
  display: inline-flex;
}

.post-tag-form-container .add-icon {
  cursor: pointer;
  color: #89909a;
  text-align: center;
}

.post-tag-form-container .button-form-container {
  background-color: #016bff;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  display: inline-block;
  font-size: 1.2em;
  height: 2em;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 6em;
}

.post-tag-form-container .button-form-container:hover {
  cursor: pointer;
  outline: none;
  box-shadow: none;
  background: #0d3a79;
}

.post-tag-form-container .button-form-container:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.post-tag-form-container .button-form-container p {
  color: #ffffff;
}

.post-tag-form-container .ico-popup {
  font-size: 22px;
}

.post-tag-form-container .post-form-container {
  display: inline-block;
}

.post-tag-form-container .close-icon {
  cursor: pointer;
  color: #d6dadc;
  float: right;
  font-size: 1.5em;
  padding-right: 0.5em;
}

.post-tag-form-container .color-choice {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  height: 1.5em;
  margin: 0 1em 0.5em 0;
  position: relative;
  text-align: center;
  width: 5em;
}

.post-tag-form-container .color-icon {
  color: #ffffff;
  cursor: pointer;
  margin-top: 0.2em;
}

.post-tag-form-container .label-form {
  font-size: 1em;
  line-height: 2;
  min-height: 30px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #ffffff;
}

.post-tag-form-container .popup-button {
  cursor: pointer;
}

.post-tag-form-container .popup-form {
  background: #0a0a0a;
  border-radius: 5px;
  border-bottom-color: #c4c9cc;
  box-shadow: 0 1px 6px #0a0a0a;
  font-size: 0.8em;
  font-weight: 100;
  letter-spacing: 0.08em;
  padding: 0.5em 0 1em 1em;
  position: relative;
  right: 0em;
  text-align: start;
  top: -3em;
  min-width: 15em;
  z-index: 70;
}

.post-tag-form-container .popup-form p {
  color: #ffffff;
}

.post-tag-form-container .text-field {
  color: #ffffff;
  background-color: transparent;
  border-width: 1.5px;
  border-radius: 0.4em;
  margin: 0 0 1em 0;
  outline: none;
  padding: 0.5rem 0.75rem;
  width: 90%;
}

.cf-tags-list-open-modal-button {
  cursor: pointer;
  font-size: 16px;
}

.cf-tags-list-open-modal-button:hover {
  color: #016bff;
  text-decoration: underline;
}

.cf-tags-list-container {
  padding: 16px;
}

.cf-tags-list-container-title {
  font-size: 24px;
  padding-bottom: 16px;
}

.cf-tags-list-container-add-button {
  margin: 0 0 20px 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

a:hover {
  text-decoration: none;
}

body {
  color: #555c66;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #555555;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

button:focus {
  outline: 0;
}

strong {
  font-weight: 600 !important;
}

.root-container {
  height: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.app-content-container {
  height: 100%;
  margin: auto;
  max-width: 768px;
  padding-bottom: 24px;
  padding-top: 96px;
}

@media (max-width: 576px) {
  .app-content-container {
    padding-top: 32px;
  }
}

.centered-content-container {
  margin-bottom: -16px;
  margin: auto;
  max-width: 1024px;
  padding-top: 32px;
}

.portal-content-container {
  padding: 40px;
}

.custom-description {
  color: #000;
  font-size: 18px;
  margin-bottom: 32px;
}

.custom-description img {
  max-width: 100%;
}

.custom-title,
.custom-subtitle {
  color: #06115e;
  font-family: "TTFirs-Bold";
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-bottom: 16px;
}

.custom-subtitle {
  font-size: 16px;
}

.widget-title-container {
  display: block;
}

.portal-selection-page-title-container {
  padding: 48px 16px;
}

/* It removes scrolling on the body when a modal is open*/
.ReactModal__Body--open {
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .custom-title {
    font-size: 24px;
  }
  .portal-selection-page-title-container {
    padding: 0px 16px;
  }
}

.overlay-button {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.ico {
  vertical-align: middle;
  padding-bottom: 2px;
  padding-right: 8px;
}

.ico-small {
  font-size: 1em;
}
.ico-medium {
  font-size: 1.3em;
}
.ico-large {
  font-size: 1.5em;
}

.admin-checkbox {
  margin: 0px 10px;
}

/* Load font Poppins from Google API */

/* Load TTFirs from S3 */
@font-face {
  font-family: "TTFirs-Bold";
  src: url("https://s3.eu-central-1.amazonaws.com/cobble-flow/cobble/assets/fonts/TT_Firs/TTFirs-Bold.otf")
    format("truetype");
}

@font-face {
  font-family: "TTFirs-ExtraBold";
  src: url("https://s3.eu-central-1.amazonaws.com/cobble-flow/cobble/assets/fonts/TT_Firs/TTFirs-ExtraBold.otf")
    format("truetype");
}

.cloudinary-image {
  width: inherit;
}

