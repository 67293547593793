.cf-tags-list-open-modal-button {
  cursor: pointer;
  font-size: 16px;
}

.cf-tags-list-open-modal-button:hover {
  color: #016bff;
  text-decoration: underline;
}

.cf-tags-list-container {
  padding: 16px;
}

.cf-tags-list-container-title {
  font-size: 24px;
  padding-bottom: 16px;
}

.cf-tags-list-container-add-button {
  margin: 0 0 20px 0;
}
